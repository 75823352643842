import React, { Fragment, useContext, useState } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import OrderInfo from "./include/OrderInfo";
import { TableLoader } from "./include/Loaders";

import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";

import "react-datepicker/dist/react-datepicker.css";

import SideNavMobile from "./include/sideNavMobile";

import Context from "../../store/Context";
import { ACTIONS } from "../../store/useGlobalState";
import ltc from "../../iamges/ltc.png";
import eth from "../../iamges/eth.png";
import OrderFilter from "./OrderFilter";
import { QueryClient, QueryClientProvider } from "react-query";
import dayjs from "dayjs";

const AdminOrders = () => {
  const dispatch = useContext(Context)?.setState;
  const [state, setState] = useState({
    sidebar: false,
    adminInfo: AuthService.getAdminAuth().admin,
    isLoading: false,
    isRequesting: false,
    showFilter: false,
    orderType: "buy",
    orderID: "",
    showOrderInfo: false,
    range: null,
    rangeStart: null,
    rangeEnd: null,
    search: "",
    status: "completed",
    type: "all",
    currency: "all",
    currentPage: 1,
    totalList: 1,
    passedOrder: {},
    orders: {
      meta: {},
      data: [],
    },

    total: 0,
    count: 1,
    users: {},
  });

  const queryClient = new QueryClient();
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [status, setStatus] = useState();
  const [type, setType] = useState();
  const [rate, setRate] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [page, setPage] = useState(0);
  const limit = 10;
  const [selectedDate, setSelectedDate] = useState("");

  const totalPages = Math.abs(Math.ceil(filteredOrders.total / limit - 1));

  const handleFetchedOrders = (newOrders) => {
    
    setFilteredOrders(newOrders);
  };

  const handlePrevPage = () => {
    setPage(page - limit);
    setPageNumber(pageNumber - 1);
  };

  const handleNextPage = () => {
    if (filteredOrders?.hasMore) {
      setPage(page + limit);
      setPageNumber(pageNumber + 1);
    }
  };

  const clearFilters = () => {
    setYear("");
    setMonth("");
    setStatus("");
    setType("");
    setSelectedDate("");
    setPage(0);
    setPageNumber(0);
  };


  return (
    <Fragment>
      <MetaTags>
        <title>Orders - HodlPay</title>
      </MetaTags>
      <QueryClientProvider client={queryClient}>
        <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
          <div className="d-flex flex-column flex-root">
            <div className="page d-flex flex-row flex-column-fluid">
              {state.sidebar && (
                <SideNavMobile
                  close={() => setState({ ...state, sidebar: false })}
                />
              )}
              <SideNav className={"bigScreen"} active="orders" />

              <div
                className="p-0 wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
              >
                <div className="header-mobile py-3">
                  <div className="container d-flex flex-stack">
                    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                      <a href="index.html">
                        <img
                          alt="Logo"
                          src="/assets/media/logos/hodlpay-black.svg"
                          className="h-35px"
                        />
                      </a>
                    </div>

                    <button
                      className="btn btn-icon btn-active-color-primary"
                      id="kt_aside_toggle"
                      onClick={() =>
                        setState({ ...state, sidebar: !state.sidebar })
                      }
                    >
                      <span className="svg-icon svg-icon-2x me-n1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="black"
                          />
                          <path
                            opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <Header title={`Orders`} />
                {state.isLoading ? (
                  <div className="pre-loader">
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className="content d-flex flex-column flex-column-fluid"
                      id="kt_content"
                    >
                      <div className="container-xxl " id="kt_content_container">
                        <div className="card mb-7  ">
                          <div className="card-body"></div>
                        </div>
                        {state.isRequesting  ? (
                          <TableLoader></TableLoader>
                        ) : (
                          <>
                            <div className="d-flex flex-row  ">
                              <div className="flex flex-col items-start lg:items-center lg:flex-row lg:justify-between my-1 w-full">
                                <div className="mt-2">
                                  <div className="fw-bolder mt-2 whitespace-nowrap">
                                    {filteredOrders.total} Orders Found
                                  </div>
                                  <div className="fw-bolder mt-2 whitespace-nowrap">
                                    USD Total: ${filteredOrders.totalAmount}
                                  </div>
                                  <div className="fw-bolder mt-2 whitespace-nowrap">
                                    GHS Total: ₵{filteredOrders.totalMomoAmount}
                                  </div>
                                </div>
                                <div className="mt-2">
                                  <OrderFilter
                                    state={state}
                                    setState={setState}
                                    handleFetchedOrders={handleFetchedOrders}
                                    limit={limit}
                                    year={year}
                                    month={month}
                                    status={status}
                                    type={type}
                                    page={page}
                                    pageNumber={pageNumber}
                                    selectedDate={selectedDate}
                                    rate={rate}
                                    setSelectedDate={(selectedDate) =>
                                      setSelectedDate(selectedDate)
                                    }
                                    setPageNumber={(pageNumber) =>
                                      setPageNumber(pageNumber)
                                    }
                                    setPage={(page) => setPage(page)}
                                    setYear={(year) => setYear(year)}
                                    setMonth={(month) => setMonth(month)}
                                    setStatus={(status) => setStatus(status)}
                                    setType={(type) => setType(type)}
                                    setRate={setRate}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-4  lg:justify-end  pb-7">
                              <div
                                className="text-red-600 cursor-pointer "
                                onClick={() => clearFilters()}
                              >
                                Clear Filters
                              </div>
                            </div>

                            <div className="card card-flush">
                              <div className="card-body pt-0">
                                {filteredOrders?.data?.length === 0 ? (
                                  <div className="text-center py-20">
                                    <span className="symbol">
                                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <g
                                            stroke="none"
                                            strokeWidth="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <rect
                                              x="0"
                                              y="0"
                                              width="24"
                                              height="24"
                                            />
                                            <rect
                                              fill="#000000"
                                              opacity="0.3"
                                              x="2"
                                              y="2"
                                              width="10"
                                              height="12"
                                              rx="2"
                                            />
                                            <path
                                              d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                                              fill="#000000"
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                    </span>
                                    <p className="text-gray-400 fs-4 fw-bold py-3">
                                      No orders found.
                                    </p>
                                  </div>
                                ) : (
                                  <div className="table-responsive">
                                    <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                                      <thead className="fs-7 text-gray-400 text-uppercase">
                                        <tr>
                                          <th className="min-w-120px">User</th>
                                          <th className="min-w-20px text-center">
                                            Currency
                                          </th>
                                          <th className="min-w-90px"></th>
                                          <th className="min-w-50px">
                                            USD Amount
                                          </th>
                                          <th className="min-w-60px">Rate</th>
                                          <th className="min-w-120px">Total</th>
                                          <th className="min-w-90px">Date</th>
                                          <th className="min-w-50px">Status</th>
                                          <th className="min-w-50px">Manuel Order</th>
                                          <th className="min-w-50px text-end"></th>
                                        </tr>
                                      </thead>
                                      <tbody></tbody>
                                      <tbody>
                                        {filteredOrders?.data?.map(
                                          (data, index) => {
                                            let ghs =
                                              parseFloat(data.fee * data.rate) +
                                              parseInt(data.amount * data.rate);
                                            let ghsRate = parseFloat(data.rate);
                                            let date = dayjs(
                                              new Date(data.createdAt)
                                            ).format("MMM D, YYYY hh:mm a");
                                            return (
                                              <tr key={index}>
                                                <td className="text-start fw-bold">
                                                  {
                                                    data?.transaction
                                                      ?.accountName
                                                  }
                                                </td>
                                                <td className="text-center">
                                                  <div className="symbol symbol-45px me-2">
                                                    {data.asset === "BTC" ? (
                                                      <span className="symbol-label bg-light-warning">
                                                        <img
                                                          src="/assets/media/svg/brand-logos/btc.svg"
                                                          className="h-50 align-self-center"
                                                          alt=""
                                                        />
                                                      </span>
                                                    ) : data.asset === "LTC" ? (
                                                      <span className="symbol-label bg-cyan-500">
                                                        <img
                                                          src={ltc}
                                                          className="h-50 align-self-center"
                                                          alt=""
                                                          style={{
                                                            verticalAlign:
                                                              "middle",
                                                          }} // Align LTC image vertically centered
                                                        />
                                                      </span>
                                                    ) : data.asset === "ETH" ? (
                                                      <span className="symbol-label bg-gray-300">
                                                        <img
                                                          src={eth}
                                                          className="h-50 align-self-center"
                                                          alt=""
                                                          style={{
                                                            verticalAlign:
                                                              "middle",
                                                          }} // Align ETH image vertically centered
                                                        />
                                                      </span>
                                                    ) : (
                                                      <span className="symbol-label bg-light-success">
                                                        <img
                                                          src="/assets/media/svg/brand-logos/usdt.svg"
                                                          className="h-50 align-self-center"
                                                          alt=""
                                                        />
                                                      </span>
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  <small className="text-dark fw-bolder text-hover-primary mb-1 fs-6">
                                                    {data.currency}
                                                    {data.type === "buy" ? (
                                                      <span className="badge badge-light-primary ms-2">
                                                        Buy Order
                                                      </span>
                                                    ) : (
                                                      <span className="badge badge-light-dark ms-2">
                                                        Sell Order
                                                      </span>
                                                    )}
                                                  </small>
                                                </td>
                                                <td>
                                                  <span className="text-dark fw-bold d-block">
                                                    ${data.amount}{" "}
                                                    {data.type === "buy" && (
                                                      <>
                                                        •{" "}
                                                        <span className="text-muted">
                                                          Fees:
                                                        </span>{" "}
                                                        ${data.fee}
                                                      </>
                                                    )}
                                                  </span>
                                                </td>
                                                <td>
                                                  <small className="text-dark fw-bolder text-hover-primary mb-1">
                                                    GHS {ghsRate}
                                                  </small>
                                                </td>
                                                <td>
                                                  <small className="text-dark fw-bolder text-hover-primary mb-1">
                                                    GHS {ghs}
                                                  </small>
                                                </td>
                                                <td className="text-start text-muted fw-bold">
                                                  <div className="flex">
                                                    <b className="mr-1">
                                                      {date}
                                                    </b>
                                                  </div>
                                                </td>
                                                <td className="text-start">
                                                  {[
                                                    "pending_payment",
                                                    "pending_verification",
                                                    "pending_transfer",
                                                    "pending_payout",
                                                    "pending",
                                                  ].includes(data.status) && (
                                                    <span className="badge badge-light-warning">
                                                      Pending
                                                    </span>
                                                  )}

                                                  {data.status?.includes(
                                                    "awaiting.approval"
                                                  ) && (
                                                    <span className="badge badge-light-success">
                                                      Waiting Approval
                                                    </span>
                                                  )}

                                                  {data.status ===
                                                    "completed" && (
                                                    <span className="badge badge-light-primary">
                                                      Completed
                                                    </span>
                                                  )}

                                                  {data.status ===
                                                    "approved" && (
                                                    <span className="badge badge-light-primary">
                                                      Approved
                                                    </span>
                                                  )}

                                                  {data.status ===
                                                    "expired" && (
                                                    <span className="badge badge-light ">
                                                      Expired
                                                    </span>
                                                  )}

                                                  {data.status ===
                                                    "canceled" && (
                                                    <span className="badge badge-light-danger">
                                                      Canceled
                                                    </span>
                                                  )}
                                                  {data.status === "failed" && (
                                                    <span className="badge badge-light-danger">
                                                      Failed
                                                    </span>
                                                  )}
                                                  {data.admin_info != null && (
                                                    <>
                                                      <br />
                                                      <small className="p-2 text-muted">
                                                        Updated By:{" "}
                                                        {
                                                          data.admin_info
                                                            .full_name
                                                        }
                                                      </small>
                                                    </>
                                                  )}
                                                </td>
                                                <td>
                                                <td>
  {data.isHighOrder === true ? (
    <small className="text-green-600">True</small>
  ) : (
    <small className="text-red-500">False</small>
  )}
</td>
</td>



                                                <td className="text-end">
                                                  <button
                                                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                                    onClick={() => {
                                                      dispatch({
                                                        type: ACTIONS.modal_2_On,
                                                        content: (
                                                          <OrderInfo
                                                            orderID={data.id}
                                                            passedOrder={data}
                                                          />
                                                        ),
                                                      });
                                                    }}
                                                  >
                                                    <span className="svg-icon svg-icon-2">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                      >
                                                        <rect
                                                          opacity="0.5"
                                                          x="18"
                                                          y="13"
                                                          width="13"
                                                          height="2"
                                                          rx="1"
                                                          transform="rotate(-180 18 13)"
                                                          fill="black"
                                                        />
                                                        <path
                                                          d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                                          fill="black"
                                                        />
                                                      </svg>
                                                    </span>
                                                  </button>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="pt-10 flex w-full justify-between">
                          <div className="flex w-full justify-between">
                            <button
                              className={` h-3 w-4 ${
                                pageNumber === 0
                                  ? "opacity-50 cursor-not-allowed"
                                  : "cursor-pointer"
                              }`}
                              disabled={pageNumber === 0}
                              onClick={handlePrevPage}
                            >
                              <i className="fa fa-arrow-left mr-1"></i>
                              <p>Previous</p>
                            </button>

                            <div>
                              <span className="text-[12px]">
                                Page {pageNumber + 1} of {totalPages}
                              </span>
                            </div>

                            <button
                              className={` h-3 w-4 ${
                                pageNumber + 1 === totalPages
                                  ? "opacity-50 cursor-not-allowed"
                                  : "cursor-pointer"
                              }`}
                              onClick={handleNextPage}
                              disabled={
                                !filteredOrders?.hasMore ||
                                pageNumber + 1 === totalPages
                              }
                            >
                              <i className="fa fa-arrow-right mr-1"></i>
                              <p>Next</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Footer />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </QueryClientProvider>
    </Fragment>
  );
};

export default AdminOrders;
